<template>
  <base-form
    model="survey"
    @save="survey_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.survey.loading || $apollo.queries.allowedSurveyTypes.loading"
    :currentValue="survey"
    :defaultValue="baseItem"
    @input="newValue = $event"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

const SURVEY_FORM_FRAGMENT = gql`
  fragment surveyForm on Survey {
    id
    name
    title
    class
    container {
      ... on Container {
        id
        name
      }
    }
  }
`;

export default {
  components: { BaseForm },
  name: "SurveyForm",
  props: {
    survey_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    survey_id(val) {
      if (val == null) this.survey = {};
    },

    newValue(val, oldVal) {
      if (val?.container_type !== oldVal?.container_type) {
        this.setPathAndQuery();
      }
    },

    survey(val) {
      if (val?.container_type) {
        this.setPathAndQuery();
      }
    },
  },

  data() {
    return {
      newValue: {},
      survey: {},
      saving: false,
      allowedSurveyTypes: [],
      path: null,
      query: null,
      type: null,
    };
  },

  apollo: {
    allowedSurveyTypes: {
      query: gql`
        query AllowedSurveyTypes {
          me {
            id
            allowedContainerTypes
          }
        }
      `,
      update: (data) => data.me.allowedContainerTypes,
    },

    survey: {
      query: gql`
        query Survey($id: ID!) {
          survey(id: $id) {
            ...surveyForm
          }
        }
        ${SURVEY_FORM_FRAGMENT}
      `,
      update: (data) => {
        const survey = data.survey;
        survey.container_type = survey.container?.__typename.toUpperCase();
        return survey;
      },
      variables() {
        return { id: this.survey_id };
      },
      skip() {
        return this.survey_id == null;
      },
    },
  },

  computed: {
    fields() {
      return [
        {
          name: "title",
          validation: {
            required,
          },
          cols: 12,
        },
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "class",
          type: "api",
          path: "availableSurveyClasses",
          query: gql`
            query AvailableSurveyClasses {
              availableSurveyClasses
            }
          `,
          validation: {
            required,
          },
        },
        {
          name: "container_type",
          type: "morph-type",
          items: this.allowedSurveyTypes.map((value) => ({
            text: this.$t(`options.survey.container_type.${value}`),
            value,
          })),
          validation: {
            required,
          },
        },
        {
          name: "container",
          type: "query",
          label: this.type ? this.$t(`options.survey.container_type.${this.type}`) : "",
          path: this.path,
          query: this.query,
          validation: {
            required,
          },
          isVisible: () => !(this.query == null || this.path == null),
        },
      ];
    },
  },

  methods: {
    setPathAndQuery() {
      this.path = this.getPath();
      this.query = this.getQuery();
    },

    getType() {
      return (this.type = this.newValue.container_type ?? this.survey.container?.__typename.toUpperCase());
    },

    getQuery() {
      let query;

      switch (this.getType()) {
        case "CLIENT":
          query = gql`
            query ManagedClients {
              me {
                id
                managedClients {
                  id
                  name
                }
              }
            }
          `;
          break;

        case "AREA":
          query = gql`
            query ManagedAreas {
              me {
                id
                managedAreas {
                  id
                  name
                }
              }
            }
          `;
          break;

        case "RESIDENCE":
          query = gql`
            query ManagedResidences {
              me {
                id
                managedResidences {
                  id
                  name
                }
              }
            }
          `;
          break;

        default:
          query = null;
          break;
      }

      return query;
    },

    getPath() {
      switch (this.getType()) {
        case "CLIENT":
          return "me.managedClients";

        case "AREA":
          return "me.managedAreas";

        case "RESIDENCE":
          return "me.managedResidences";

        default:
          return null;
      }
    },

    update() {
      this.saving = true;

      let input = this.newValue;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Survey",
            fragment: SURVEY_FORM_FRAGMENT,
            fragment_name: "surveyForm",
          }),
          variables: {
            id: this.survey_id,
            input,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      let input = this.newValue;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Survey",
            fragment: SURVEY_FORM_FRAGMENT,
            fragment_name: "surveyForm",
          }),
          variables: {
            input,
          },
        })
        .then(({ data: { createSurvey } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createSurvey);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
