<template>
  <v-container>
    <v-card class="overflow-hidden" rounded="lg">
      <base-table
        v-if="!$apollo.queries.surveys.loading"
        :title="$tc('models.survey', 2)"
        :headers="headers"
        :items="surveys"
        :itemRoute="itemRoute"
        itemRouteIcon="mdi-wrench"
      >
        <template v-slot:form="{ selected_id, close, duplicating }">
          <survey-form
            :survey_id="selected_id"
            :duplicating="duplicating"
            @cancel="close"
            @save="close"
            @created="(newElement) => surveyCreated(newElement, close)"
          />
        </template>

        <template v-slot:[`class`]="{ item }">
          <v-chip color="primary" small>{{ $t("survey.classes." + item.class) }}</v-chip>
        </template>

        <template v-slot:[`container.__typename`]="{ item }">
          {{ $t("survey.containerTypes." + item.container.__typename) }} -
          {{ item.container.name }}
        </template>

        <template v-slot:extra-actions="{ item }">
          <v-btn small icon :to="{ name: 'SurveyPreview', params: { id: item.id } }">
            <v-icon small>mdi-file-find {{ item.class }}</v-icon>
          </v-btn>
        </template>
      </base-table>
      <v-skeleton-loader v-if="$apollo.queries.surveys.loading" class="pa-3" type="table"></v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import BaseTable from "../tables/BaseTable.vue";
import SurveyForm from "../forms/SurveyForm.vue";
import { addToCacheArray } from "../../apollo/helpers";

const query = gql`
  query ViewableSurveys {
    me {
      id
      viewableSurveys {
        id
        name
        title
        class
        container {
          ... on Container {
            id
            name
          }
        }
      }
    }
  }
`;

export default {
  name: "Surveys",
  components: {
    BaseTable,
    SurveyForm,
  },

  data() {
    return {
      surveys: [],
      headers: [
        {
          text: this.$t("survey.name"),
          value: "name",
        },
        {
          text: this.$t("survey.title"),
          value: "title",
        },
        {
          text: this.$t("survey.class"),
          value: "class",
          type: "template",
        },
        {
          text: this.$t("survey.level"),
          value: "container.__typename",
          type: "template",
        },
      ],
      query,
    };
  },
  apollo: {
    surveys: {
      query,
      update: (data) => data.me.viewableSurveys,
    },
  },
  methods: {
    surveyCreated(newElement, close) {
      close();

      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: { query },
        newElement,
        queryField: "me.viewableSurveys",
        unshift: true,
      });
    },
    itemRoute(item) {
      return {
        name: "SurveyBuilder",
        params: { id: item.id },
      };
    },
  },
};
</script>
